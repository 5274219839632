import React from 'react';
import Content from "../views/Blog";
import seo from './blog/metadata.json';
import { locale } from '../locale';
import { strapiBlogpostUrl } from './constants';

const getPostCount = () => fetch(`${strapiBlogpostUrl}/count?localization=${locale}`, {
  method: 'GET'
}).then(res => res.json());

const getPosts = (start) => fetch(`${strapiBlogpostUrl}?localization=${locale}&_sort=createdAt:DESC`
  + `&_start=${start}&_limit=2`, {
  method: 'GET'
}).then(res => res.json());

class Blog extends React.Component {
  constructor() {
    super();
    this.state = {
      blogs: [],
      current: 0,
      count: 0,
    };

    this.onNext = this.onNext.bind(this);
    this.onPrev = this.onPrev.bind(this);
  }

  onPrev() {
    const { current } = this.state;
    const start = current - 2 > 0 ? current - 2 : 0;
    getPosts(start)
    .then(blogs => {
      if (!blogs.error) this.setState({ blogs, current: start });
      else this.setState({ blogs: [] });
    }).catch(() => this.setState({ blogs: [] }));
  }

  onNext() {
    const { current, count } = this.state;
    const end = current + 2 < count ? current + 2 : count - 1;
    getPosts(end)
    .then(blogs => {
      if (!blogs.error) this.setState({ blogs, current: end });
      else this.setState({ blogs: [] });
    }).catch(() => this.setState({ blogs: [] }));
  }

  componentDidMount() {
    Promise.all([
      getPosts(0),
      getPostCount()
    ]).then(([blogs, count]) => {
      if (!blogs.error) this.setState({
        blogs,
        count
      });
      else this.setState({ blogs: [] });
    }).catch(() => this.setState({ blogs: [] }));
  }

  render() {
    const { blogs, count, current } = this.state;
    return <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        fontSize: 15,
      }}>
        Staging environment
      </div>
      <Content seo={seo} location={this.props.location}
        current={current}
        onNext={this.onNext}
        onPrev={this.onPrev}
        count={count}
        blogs={blogs}/>
    </>
  }
}

export default Blog;