import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <Grid item xs={12} md={12} lg={5} xl={4} container justify='center' alignContent='center' alignItems='center'
    className={styles.wrapper}>
    <Grid item container className={styles.container}  justify='center' alignContent='center' direction='column'>
      <Typography weight='bold' align='left' variant='h5' className={styles.title}>
        {i18n('Who are we?')}
      </Typography>
      <Typography weight='light' color='dark' align='left' variant='subtitle2'>
        {i18n('OBI + is a Danish IT company that provides an application platform for connected car services.')}
        <br/><br/>
        {i18n('Our services bridge the gap between what service providers do today and what vehicle users want. Here we share knowledge about the automotive industry, connected car technologies, fleet management, tips & use cases that you can take advantage of.')}
      </Typography>
    </Grid>
  </Grid>
)